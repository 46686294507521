import React from 'react'
import { StaticQuery, graphql } from "gatsby"
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ExitToIcon from '@material-ui/icons/ExitToApp'

import Layout from '../../../../../components/layout'
import SEO from '../../../../../components/seo'
import Paragraphs from '../../../../../components/paragraph'
import Heading from '../../../../../components/heading'
import HeroBox from '../../../../../components/herobox'
import HotelInfo from '../../../../../components/hotel/hotelInfo'
import GalleryHotel from '../../../../../components/gallery/hotels/mine/hotel'

const useStyles = makeStyles(theme => ({
    root: {

    },
    rootContainer: {
        padding: 0,
    },
    galleryContainer: {
        padding: '15px',
    },
    hero: {
        padding: '1rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '80vh',
        textAlign: 'center',
        color: '#ffffff',
    },
    heading1: {
        backgroundColor: 'rgba(72,189,152,.9)',
        padding: '20px;'
    },
    paragraphs: {
        paddingTop: '30px;'
    },
    paragraph: {
        backgroundColor: 'rgba(0,0,0,.5)',
    },
    button: {
        textAlign: 'right',
        marginBottom: '1.45rem',
        paddingRight: '30px',
    }
}));

const MineHotelIndexPage = ({ data }) => {
    const language = 'zh_hant';
    const hotel = data.hotelsJson;
    const page = hotel.pages[language];
    const rakuten = hotel.rakuten;
    const slug = 'hotel/'+hotel.prefecture_name+'/'+hotel.name;
    const gallery1 = 'hotels/'+hotel.name;
    const classes = useStyles();
    return (
        <Layout language={language} slug={slug}>
            <SEO title={page.hotelName} />
            <HeroBox
                image={data.hero.childImageSharp.fluid}
                stationName={page.hotelName}
                descriptions={page.descriptions}
                language={language}
            />
            <Container component="section" className={classes.rootContainer}>
            <Box component="article">
                <HotelInfo
                    language={language}
                    hotel={hotel}
                    page={page}
                    rakuten={rakuten}
                />
            {
                page.sections.map((section, i) => {
                    if (!section.isChild) {
                            if ('article' === section.type) {                                  
                                if (section.link) {
                                        return (
                                            <section key={i}>
                                                <Heading title={section.title} isChild={section.isChild} />
                                                <Paragraphs paragraphs={section.paragraphs} />
                                                <div className={classes.button}>
                                                    <Button variant="outlined" color="primary" href={section.link}>
                                                        {section.title} <ExitToIcon />
                                                    </Button>
                                                </div>
                                            </section>
                                        )
                                    } else {
                                        return (
                                            <section key={i}>
                                                <Heading title={section.title} isChild={section.isChild} />
                                                <Paragraphs paragraphs={section.paragraphs} />
                                            </section>
                                        )
                                    }
                            } else if ('gallery' === section.type) {
                                if (gallery1 === section.gallery.directory) {
                                    const items = page.sections[i].gallery.items;
                                    return (
                                        <section className={classes.galleryContainer} key={i}>
                                            <GalleryHotel idxSection={i} language={page.language} items={items} />
                                        </section>
                                    )
                                } else {

                                }
                            } else if ('link' === section.type) {

                            } else {

                            }
                        } else {
                            if ('article' === section.type) {
                                if (section.link) {
                                        return (
                                            <section key={i}>
                                                <Heading title={section.title} isChild={section.isChild} />
                                                <Paragraphs paragraphs={section.paragraphs} />
                                                <div className={classes.button}>
                                                    <Button variant="outlined" color="primary" href={section.link}>
                                                        {section.title} <ExitToIcon />
                                                    </Button>
                                                </div>
                                            </section>
                                        )
                                    } else {
                                        return (
                                            <section key={i}>
                                                <Heading title={section.title} isChild={section.isChild} />
                                                <Paragraphs paragraphs={section.paragraphs} />
                                            </section>
                                        )
                                    }
                            } else if ('gallery' === section.type) {
                                if (gallery1 === section.gallery.directory) {
                                    const items = page.sections[i].gallery.items;
                                    return (
                                        <section className={classes.galleryContainer} key={i}>
                                            <GalleryHotel idxSection={i} language={page.language} items={items} />
                                        </section>
                                    )
                                } else {

                                }
                            } else if ('link' === section.type) {
                                
                            } else {

                            }
                        }
                })
            }</Box>
            </Container>
            {/* <pre>{JSON.stringify(data, null, 4)}</pre> */}
        </Layout>
    );
};

export const query = graphql`
  {
    hotelsJson(name: {eq: "mine"}) {
      id
      lat
      lon
      prefecture
      city
      address
      zip
      website
      name
      nearestStationID
      access {
        by
        time
        unit
      }
      prefecture_id
      phoneNumber
      prefecture_name
      pages {
        zh_hant {
          categories
          descriptions
          hotelName
          language
          nearestStationName
          reservations {
            title
            url
          }
          sections {
            isChild
            paragraphs
            title
            type
            gallery {
              directory
              items {
                filename
                title
              }
            }
          }
          overview
          pickup
          access
          address {
            address1
            address2
            city
            prefecture
          }
        }
      }
      rakuten {
        hotelPolicyInfo {
          availableCreditCard {
            card
          }
        }
        hotelFacilitiesInfo {
          aboutBath {
            bathQuality
            bathType
            bathBenefits
          }
          hotelRoomNum
          hotelFacilities {
            item
          }
          roomFacilities {
            item
          }
        }
        hotelDetailInfo {
          checkinTime
          checkoutTime
          lastCheckinTime
        }
        hotelBasicInfo {
          latitude
          longitude
          access
          hotelNo
          hotelName
          nearestStation
          parkingInformation
          reviewCount
          reviewAverage
        }
      }
    }
    hero:file(relativePath: {eq: "hotels/mine/hero.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1000, maxHeight: 1000, jpegQuality: 100) {
                ...GatsbyImageSharpFluid_withWebp
            }

        }
    }
  }
`

export default MineHotelIndexPage
